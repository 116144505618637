import FieldType from '@constants/field-type'

export default {
  getForm() {
    return [
      {
        name: 'affiliate_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website',
        value_key: 'affiliate_name',
        only_create: true,
      },
      {
        name: 'name',
        type: 'text',
        disabled: true,
        only_edit: true,
      },
      {
        name: 'type',
        required: true,
        type: 'list-one',
        options: ['default', 'rage_ppc', 'rage_seo', 'sports', 'partnership'],
        selectLabel: 'label',
        display_name: 'type',
      },
      {
        name: 'url',
        type: 'text',
      },
      {
        name: 'enabled_modules',
        search_url: 'hercules/sites/modules',
        display_name: 'Modules enabled',
        type: 'list-many',
        validate_changes: true,
        required: false,
        selectLabel: 'label',
        only_edit: true,
        fetch_on_load: false,
      },
      {
        name: 'meta_title_suffix',
        type: 'text',
      },
      {
        name: 'enable_content_type',
        type: 'checkbox',
        default: 0,
      },
      {
        name: 'gtm_id',
        type: 'text',
        display_name: 'GTM ID',
      },
      {
        name: 'logo_asset_id',
        required: false,
        display_name: 'Logo',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
      },
      {
        name: 'events_page_default_path_prefix',
        type: 'text',
        show_if: { field: 'type', value: ['sports'] },
        required: false,
        help_text: 'Override the default path prefix for autogenerated events pages.',
      },
      {
        name: 'events_page_reindex_path_prefix',
        type: 'text',
        only_edit: true,
        required: false,
        show_if: { field: 'type', value: ['sports'] },
        help_text: 'Override the reindex path prefix for autogenerated events pages.',
      },
      {
        name: 'robots_txt',
        type: 'textarea',
        rows: 16,
        display_name: 'Robots.txt',
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/sites',
      name: 'Site',
      editWith: 'enabled_modules',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
