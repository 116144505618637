<template>
  <div
    class="card mx-4 border border-bottom-0"
    v-bind:class="{ show: (canEdit && pageModule.visible) || openModuleValues }"
  >
    <div class="card-header py-3 d-flex border-bottom module-drag" @click="setVisibility">
      <span
        v-bind:class="[
          'badge bg-black-25 font-size-md align-self-center py-2',
          pageModule.removed ? 'faded' : '',
        ]"
      >
        {{ index + 1 }}
      </span>

      <div class="ml-3">
        <div class="d-flex align-items-center">
          <div>
            <h5 style="margin-right: 10px" v-bind:class="['card-title', pageModule.removed ? 'faded' : '']">
              {{ moduleName }}
            </h5>
            <div v-if="showWarning">
              <span v-for="(toplist, index) in warningToplists" :key="index" class="text-danger text-xs">
                This toplist (Toplist ID: {{ toplist.id }}) would impact website performance negatively
                because it has {{ toplist.count }} operators and load more is disabled. Please review.
              </span>
            </div>
            <span v-bind:class="editableClass()" v-if="!canEdit">{{ editableLabel() }}</span>
          </div>
        </div>
        <div v-bind:class="['text-muted mt-1', pageModule.removed ? 'faded' : '']">{{ preview }}</div>
      </div>

      <div class="d-flex ml-auto align-items-center align-self-center" style="gap: 14px">
        <button
          class="btn btn-warning btn-sm"
          v-if="showCloneModalButton"
          :disabled="pageModule.removed"
          @click="openCloneModal"
        >
          Clone
          <i class="uil uil-copy"></i>
        </button>

        <button
          class="btn btn-primary btn-sm"
          :disabled="!canDelete || pageModule.removed"
          @click="$emit('addModule', index)"
        >
          Add
          <i class="uil uil-corner-right-down"></i>
        </button>

        <button
          v-if="showPreview"
          class="btn btn-secondary btn-sm"
          :disabled="!canDelete || pageModule.removed"
          @click="$emit('previewModule', index)"
        >
          Preview
          <i class="uil uil-search"></i>
        </button>

        <div
          class="switch clickable"
          :disabled="pageModule.removed"
          data-tooltip="Set the active state of a module"
          v-if="moduleOwnerType !== 'prefilled_module_market'"
        >
          <input
            type="checkbox"
            :id="index"
            :disabled="pageModule.removed"
            :checked="pageModule.active"
            @click="(event) => (pageModule.active = event.target.checked)"
          />

          <label :for="index"></label>
        </div>

        <div>
          <template v-if="pageModule.removed">
            <button class="btn btn-primary bg-green btn-sm" @click="toggleSoftDelete">
              <i class="uil uil-redo text-black pl-1" />
            </button>
          </template>

          <template v-else>
            <button :disabled="!canDelete" v-bind:class="editableButton()" @click="toggleSoftDelete">
              <i v-bind:class="editableIcon()" />
            </button>
          </template>
        </div>
      </div>
    </div>

    <div
      class="card-body"
      v-bind:class="[pageModule.removed ? 'faded' : '', pageModule.module.name + '-module']"
      v-if="(canEdit && pageModule.visible) || openModuleValues"
    >
      <div class="nav nav-tabs mt-1 mb-3">
        <div
          class="nav-item"
          v-for="tab in tabs"
          v-bind:key="tab"
          v-bind:class="{ active: activeTab === tab }"
          v-on:click="activeTab = tab"
        >
          <button class="nav-link">{{ tab === 'Specific' ? moduleName : 'General' }}</button>
        </div>
      </div>

      <div class="tab-content" v-if="!isAnchorModule" v-bind:class="{ 'd-none': activeTab !== 'Specific' }">
        <component
          v-if="customEditComponents.includes(pageModule.module.name)"
          :siteId="siteId"
          :affiliateId="affiliateId"
          :marketId="marketId"
          :showLinkToMenuItems="showLink"
          @refreshPreview="preview(true)"
          v-bind:is="getEditorComponentByModuleID(pageModule.module.name)"
          :pageModule="pageModule"
        />

        <template v-else>
          <page-edit-module-field
            v-for="(moduleField, index) in pageModule.module_value_fields"
            :hidden="isFieldHidden(pageModule.module.name, moduleField)"
            v-bind:key="index"
            :index="index"
            :siteId="siteId"
            :affiliateId="affiliateId"
            :marketId="marketId"
            :moduleField="moduleField"
          />
        </template>
      </div>

      <div class="tab-content" v-bind:class="{ 'd-none': activeTab !== 'General' }">
        <div class="row">
          <div class="col">
            <base-input label="Title" v-model="pageModule.title" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-select
              label="Title tag"
              :clearable="true"
              v-model="pageModule.title_tag"
              :custom-options="['h2', 'h3', 'h4', 'h5']"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <base-rich-text
              :modelValue="pageModule.module_introduction"
              :siteId="siteId"
              :affiliateId="affiliateId"
              :marketId="marketId"
              label="Module introduction"
              height="120"
              @valueChanged="(value) => (pageModule.module_introduction = value)"
            />
          </div>
        </div>

        <div class="row" v-if="pageModule.module && !isAnchorModule">
          <div class="col">
            <base-input
              @update:modelValue="validateAnchorLabel"
              label="Anchor label"
              v-model="pageModule.anchor_label"
            />
          </div>

          <div class="col">
            <base-checkbox label="Anchored" v-model="pageModule.anchored" />
          </div>
        </div>

        <div class="row" v-if="backgroundColors.length > 0 && pageModule.module">
          <div class="col">
            <base-select
              label="Background color"
              v-model="pageModule.background_color_id"
              :custom-options="backgroundColors"
              :clearable="true"
              option-label-key="label"
            />
          </div>
        </div>

        <div class="row" v-if="moduleStyles.length > 0 && pageModule.module">
          <div class="col">
            <base-select
              label="Style"
              v-model="pageModule.style_id"
              :custom-options="moduleStyles"
              :clearable="true"
              option-label-key="name"
            />
          </div>
        </div>

        <div class="row" v-if="pageModule.module && !isAnchorModule">
          <div class="col">
            <base-link
              :enableLabel="true"
              label="See more link"
              :value="getLinkTypeValue()"
              :siteId="siteId"
              :marketId="marketId"
              @valueChanged="(value) => setBaseLinkValue(value)"
              :inlineLayout="true"
            />
          </div>
        </div>
      </div>
    </div>

    <CloneModal
      v-if="showCloneModal"
      @close="showCloneModal = false"
      ref="cloneModal"
      :marketId="marketId"
      :siteId="siteId"
      :position="index"
      :pageModule="pageModule"
    >
    </CloneModal>
  </div>
</template>

<script>
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'

import BaseInput from '@atoms/fields/base-input.vue'
import BaseLink from '@molecules/fields/base-link.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelect from '@/components/fields/base-select'
import BaseRichText from '@atoms/fields/base-rich-text.vue'

import Cards from '@molecules/cms/modules/cards'
import CardsV2 from '@molecules/cms/modules/cards-v2'
import Content from '@molecules/cms/modules/content.vue'
import Spotlight from '@molecules/cms/modules/spotlight'
import Iframe from '@molecules/cms/modules/iframe'
import Archive from '@molecules/cms/modules/archive'
import Menu from '@molecules/cms/modules/menu'
import CloneModal from '@molecules/cms/modals/clone-modal'

import ModuleValue from '@constants/module-value'

import { openDialog } from 'vue3-promise-dialog'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'

import TemplateService from '@services/TemplatesService'
import AssetsService from '@services/AssetsService'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: {
    PageEditModuleField,
    BaseInput,
    Cards,
    CardsV2,
    BaseCheckbox,
    BaseSelect,
    Spotlight,
    Iframe,
    BaseLink,
    BaseRichText,
    CloneModal,
    Content,
  },
  data() {
    return {
      customEditComponents: ['cards', 'menu', 'archive', 'spotlights', 'iframe', 'cards_v2', 'content'],
      color: null,
      // eslint-disable-next-line vue/no-computed-properties-in-data
      activeTab: null,
      showCloneModal: false,
      cloneModuleNotAllowed: [
        'prefilled_module',
        'prefilled_module_market',
        'prefilled_module_market',
        'prefilled_module',
        'template_block',
      ],
      cardTypeName: null,
      spotlightTitle: null,
    }
  },
  mounted() {
    this.updateActiveTab()
  },
  watch: {
    'pageModule.visible': {
      handler() {
        this.updateActiveTab()
      },
      deep: true,
    },
  },
  computed: {
    showLink() {
      return this.$route.meta.name === 'Market Section values' ? true : false
    },
    getToplistData() {
      const topListItemIdFields = this.findChildrenFieldsByName('top_list_item_id')
      if (!topListItemIdFields || topListItemIdFields.length === 0) {
        return []
      }

      const topListItemIds = topListItemIdFields.map((field) => Number(field.value))
      return this.$store.state.dynamicForm.toplists.filter((toplist) => topListItemIds.includes(toplist.id))
    },
    warningToplists() {
      if (this.moduleName === 'Top list') {
        const topListItemIdFields = this.findChildrenFieldsByName('top_list_item_id')
        const loadMoreFields = this.findChildrenFieldsByName('top_list_item_show_load_more')

        if (!topListItemIdFields.length || !loadMoreFields.length) {
          return []
        }

        const minLength = Math.min(topListItemIdFields.length, loadMoreFields.length)

        const toplists = topListItemIdFields
          .slice(0, minLength)
          .map((topListItemIdField, i) => {
            const loadMoreField = loadMoreFields[i]

            const toplistId = Number(topListItemIdField.value)
            const toplistData = this.$store.state.dynamicForm.toplists.find(
              (toplist) => toplist.id === toplistId
            )

            if (toplistData) {
              const countCheck = toplistData.count >= 80
              const loadMoreDisabled =
                loadMoreField.value === null || loadMoreField.value === '0' || loadMoreField.value === false

              if (countCheck && loadMoreDisabled) {
                return toplistData
              }
            }
            return null
          })
          .filter(Boolean)

        return toplists
      }
      return []
    },
    showWarning() {
      return this.warningToplists.length > 0
    },
    showCloneModalButton() {
      return (
        !this.cloneModuleNotAllowed.includes(this.pageModule.value_type) &&
        this.pageModule.module.name !== 'anchor' &&
        this.pageModule.owner_type === 'page'
      )
    },
    tabs() {
      return this.isAnchorModule ? ['General'] : ['Specific', 'General']
    },
    isAnchorModule() {
      return this.pageModule.module && this.pageModule.module.name === 'anchor'
    },
    moduleStyles() {
      return this.$store.getters['cms/getStyleByModuleId'](this.pageModule.module.id)
    },
    backgroundColors() {
      return this.$store.state.cms.colors
    },
    selectedBackgroundColor() {
      if (this.pageModule.background_color_id) {
        const match = this.$store.state.cms.colors.find(
          (color) => this.pageModule.background_color_id === color.id
        )
        if (match) {
          return match.hex_code
        }
      }
      return null
    },
    showValueFromTemplateBadge() {
      return false
    },
    canEdit() {
      if (
        [ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK, ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET].includes(
          this.pageModule.value_type
        )
      ) {
        return false
      }

      return this.moduleOwnerType !== ModuleValue.OWNER_TYPE_TEMPLATE
    },
    canDelete() {
      if (this.moduleOwnerType === 'page') {
        return [
          null,
          ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET,
          ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK,
        ].includes(this.pageModule.value_type)
      }
      if (this.moduleOwnerType === 'template') {
        return true
      }
      if (this.moduleOwnerType === 'page') {
        return true
      }
      if (this.moduleOwnerType === 'market') {
        return true
      }
      return false
    },
    moduleName() {
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return this.pageModule.template_block_name
      }
      return this.pageModule.module.label
    },
    preview() {
      // prefilled module name
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET) {
        return this.pageModule.prefilled_module_name
      }

      // template block
      if (
        this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK &&
        this.pageModule.template_block_description
      ) {
        return this.pageModule.template_block_description
      }
      if (!this.canEdit && !this.isAnchorModule) {
        return 'No preview available'
      }

      let title = ''
      let heading = this.pageModule.title_tag
      let content = this.pageModule.title

      switch (this.pageModule.module.name) {
        case 'content':
          return this.handleContent(heading, content)
        case 'spotlights':
          return this.handleSpotlights(heading, content)
        case 'top_list':
          title = this.findFieldByName('top_list_title')
          return `Title: ${title.value || 'Title is empty'}`
        case 'cards_v2':
          return this.handleCardsV2(heading, content)
        case 'cards':
          return `Label: ${this.pageModule.module_value_fields[0].value}`
        case 'archive':
          var model = this.findFieldByName('archive_model_type')
          return `Model type: ${model.value || 'No model selected'}`
        case 'counter':
          title = this.findFieldByName('counter_title')
          return `Title: ${title.value || 'Title is empty'}`
        case 'anchor':
          title = heading ? heading.toUpperCase() : 'Title'
          return `${content || 'Title is empty'}`
        case 'widget':
          return `${content || 'Title is empty'}`
        case 'menu':
          var menuField = this.findFieldByName('menu_id')
          if (menuField.value_label) {
            return `Label: ${menuField.value_label}`
          }
        // eslint-disable-next-line no-fallthrough
        default:
          return 'No preview available'
      }
    },
    loadedAssets() {
      return this.$store.state.media.loadedAssetsArray
    },
  },
  props: {
    hasTemplate: {
      default: () => false,
    },
    allowModuleDelete: {},
    openModuleValues: {},
    moduleOwnerType: {},
    showPreview: {
      default: () => false,
    },
    pageModule: {
      type: Object,
      required: true,
    },
    siteId: {
      required: true,
    },
    affiliateId: {
      required: true,
    },
    marketId: {},
    index: {},
    pageTitle: {},
    toplistObject: {},
  },
  methods: {
    displayClone() {
      return !['prefilled_module', 'template_block'].includes(this.pageModule.value_type)
    },
    openCloneModal() {
      this.showCloneModal = true
    },
    updateActiveTab() {
      this.activeTab = this.isAnchorModule ? 'General' : 'Specific'
    },
    validateAnchorLabel(value) {
      if (value) {
        value
          .replace(/ /g, '-')
          .replace(/[!@£#$%^&*():;"'|\\/.,~`]/g, '')
          .toLowerCase() === '' &&
          alert('Special Characters not accepted, please enter alphabetical/numeric characters only')
      }
    },
    getLinkTypeValue() {
      return this.pageModule.see_more_link
        ? JSON.parse(this.pageModule.see_more_link)
        : { type: 'page', value: null, title: null }
    },
    setBaseLinkValue(value) {
      this.pageModule.see_more_link = value
    },
    getEditorComponentByModuleID(name) {
      switch (name) {
        case 'cards':
          return Cards
        case 'cards_v2':
          return CardsV2
        case 'menu':
          return Menu
        case 'archive':
          return Archive
        case 'spotlights':
          return Spotlight
        case 'iframe':
          return Iframe
        case 'content':
          return Content
        default:
          return
      }
    },
    isFieldHidden(name, moduleField) {
      if (name === 'top_list' && moduleField.field.name === 'top_list_enable_sorting') {
        return this.siteId !== 3
      }
      return false
    },

    editableLabel() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return 'Prefilled module'
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return 'Template block'
      }
      return 'Template module'
    },
    editableLink() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return `/site-prefilled-modules/${this.pageModule.prefilled_module_id}#markets`
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return `/site-templates/${this.pageModule.template_id}/blocks?child=${this.pageModule.template_block_id}`
      }
      return 'Template module'
    },
    editableIcon() {
      if (this.moduleOwnerType === 'prefilled_module_market') {
        return 'uil uil-trash text-muted clickable pl-1 text-gray faded'
      }

      return 'uil uil-trash text-muted clickable pl-1 text-white'
    },
    editableButton() {
      if (this.moduleOwnerType === 'prefilled_module_market') {
        return 'btn btn-primary btn-sm faded'
      }

      return 'btn btn-primary btn-sm bg-red'
    },
    editableClass() {
      if (
        [ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET, ModuleValue.VALUE_TYPE_PREFILLED_MODULE].includes(
          this.pageModule.value_type
        )
      ) {
        return this.pageModule.removed ? 'badge slanted bg-purple faded' : 'badge slanted bg-purple'
      }
      if (this.pageModule.value_type === ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK) {
        return this.pageModule.removed ? 'badge slanted bg-orange faded' : 'badge slanted bg-orange'
      }
      return this.pageModule.removed ? 'badge slanted bg-black faded' : 'badge slanted bg-black'
    },
    setVisibility(event) {
      // dont toggle module visibility if user is clicking on the module action buttons
      if (event.target.classList.contains('btn') || event.target.parentNode?.classList.contains('btn')) {
        return false
      }
      if (this.canEdit && this.moduleOwnerType !== ModuleValue.OWNER_TYPE_PREFILLED_MODULE_MARKET) {
        this.pageModule.visible = !this.pageModule.visible
        this.$forceUpdate()
      }
    },
    async deleteModule(event) {
      // prevent events fired after tabbing through the form fields and clicking enter/space from a tabbed click
      if (event.x === 0 && event.y === 0) {
        return false
      }
      if (
        await confirmDelete('Warning', `Are you sure want to delete this ${this.moduleName} module?`, 'Yes')
      ) {
        this.$emit('delete', this.pageModule.id)
      }
    },
    findChildrenFieldsByName(name) {
      let matchingFields = []

      const searchFields = (fields) => {
        if (!Array.isArray(fields)) return

        fields.forEach((item) => {
          if (item.field.name === name) {
            matchingFields.push(item)
          }

          if (item.children && item.children.length > 0) {
            searchFields(item.children)
          }
        })
      }

      searchFields(this.pageModule.module_value_fields)
      return matchingFields
    },
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find((item) => item.field.name === name)
    },
    toggleSoftDelete() {
      this.pageModule.removed = !this.pageModule.removed
      this.$emit('reRenderList')
    },

    getCardsPageType(cardType) {
      TemplateService.getOne(cardType)
        .then((response) => {
          if (response.data.success) {
            this.cardTypeName = response.data.result[0].name
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    extractHeadingOrFirstParagraph(content) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(content, 'text/html')

      const headings = doc.querySelectorAll('h2, h3, h4, h5, h6')
      if (headings.length > 0) {
        return Array.from(headings)[0].textContent.substring(0, 70).trim()
      }

      let node = doc.body.firstChild
      while (node) {
        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim()) {
          return node.textContent.substring(0, 70).trim()
        } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'P' && node.textContent.trim()) {
          return node.textContent.substring(0, 70).trim()
        }
        node = node.nextSibling
      }
      return 'Title is empty'
    },
    handleContent(heading, content) {
      let title = content
      if (title === null || title === '' || title === undefined) {
        let content = this.pageModule.module_value_fields.find((item) => {
          return item.field.name === 'content_value'
        })
        if (content) {
          content = content.value
          title = this.extractHeadingOrFirstParagraph(content)
        }
      }

      return `${title || 'Title is empty'}`
    },
    handleSpotlights(heading, content) {
      let title = content
      if (title === null || title === undefined || title === '') {
        if (this.pageModule.module_value_fields[0].value === 'image') {
          let lastElement = this.pageModule.module_value_fields.find((item) => {
            return item.field.name === 'spotlights_list'
          }).children[0].children

          let imageId = lastElement.find((item) => {
            return item.field.name === 'spotlights_list_item_image'
          }).value

          this.getImageTitle(imageId)

          if (this.spotlightTitle) {
            title = this.spotlightTitle
          } else {
            title = 'Title is empty'
          }
        } else if (['image_text'].includes(this.pageModule.module_value_fields[0].value)) {
          let spotlightsTitle = this.pageModule.module_value_fields.find((item) => {
            return item.field.name === 'spotlights_list'
          })
          title = spotlightsTitle.children[0].children[2].value
        } else if (['icon'].includes(this.pageModule.module_value_fields[0].value)) {
          let spotlightsTitle = this.pageModule.module_value_fields.find((item) => {
            return item.field.name === 'spotlights_list'
          })
          title = spotlightsTitle.children[0].children[7].value
        }
      }
      return `${title || 'Title is empty'}`
    },
    getImageTitle(imageId) {
      if (this.loadedAssets.findIndex((value) => value.id == imageId) !== -1) {
        this.spotlightTitle = this.loadedAssets.find((value) => value.id == imageId).title
        return
      }

      AssetsService.getOne(imageId).then((response) => {
        if (response.data.success) {
          this.spotlightTitle = response.data.result[0].title
          this.$store.dispatch('media/setLoadedAssetsArray', response.data.result[0])
        }
      })
    },
    handleCardsV2(heading, content) {
      let titlePrefix = heading ? heading.toUpperCase() : 'Title'
      let title = content
      if (title === null || title === undefined || title === '') {
        let pageTypeSelector = this.pageModule.module_value_fields.find((item) => {
          return item.field.name === 'cards_v2_page_type_selector'
        }).value

        if (pageTypeSelector === 'Multi Select' && this.hasTemplate) {
          title = 'Multi Select'
        } else if (pageTypeSelector === 'Single Select' || !this.hasTemplate) {
          let cardType = this.pageModule.module_value_fields.find((item) => {
            return item.field.name === 'cards_page_type'
          }).value

          if (cardType) {
            this.getCardsPageType(cardType)
            title = this.cardTypeName
          }
        } else {
          titlePrefix = 'Cards page type'
          title = 'All'
        }
      }
      return `${titlePrefix}: ${title || 'Title is empty'}`
    },
  },
  unmounted() {
    this.$store.dispatch('app/setBaseModelSelectLoadedPage', null)
    this.$store.dispatch('media/setLoadedAssetsArray', null)
  },
}
</script>

<style scoped>
.card:hover {
  border-color: #969696;
}

.card-body {
  overflow-x: inherit;
}

.module-drag {
  cursor: grab;
}
.text-xs {
  font-size: 12px;
}
</style>
