<template>
  <data-table
    model="cms/Color"
    service-file="ColorsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    :column-templates="columnTemplates()"
    :extraDeleteInfo="{
      title: 'Careful, this is a destructive action!',
      message: 'All modules that use this colour will be affected.',
    }"
    pusherModel="sites-colors"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'
import BasicOptions from '@mixins/BasicOptions'

export default {
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    path() {
      return `hercules/sites/colors?with=site&site_id=${this.$route.params['id']}`
    },
    columns() {
      return [
        { field: 'label', type: 'text', filterable: true },
        { field: 'hex_code', type: 'text', filterable: true, template: TableColumnTemplate.CLIPBOARD },
        { field: 'hex_code', type: 'text', filterable: true, template: TableColumnTemplate.COLOR },
        Object.assign({}, BasicOptions.newActiveColumn(), { template: TableColumnTemplate.BOOLEAN }),
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        site: function (row) {
          return row.site.name
        },
      })
    },
    createDefaults() {
      return {
        site_id: parseInt(this.$route.params['id']),
      }
    },
  },
}
</script>
